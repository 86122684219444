import { Observable, Subject } from "rxjs";
import validateModal from "../components/ValidateModal/ValidateModal";

const validateModalService = {
  message: new Subject<any>(),
  getMessage: function (): Observable<any> {
    return validateModalService.message.asObservable();
  },
  sendMessage: function (message: any) {
    validateModalService.message.next(message);
  },
};
export default validateModalService;
