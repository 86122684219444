import React, {FC, useEffect} from 'react';
import styles from './ValidateModal.module.css';
import validateModalService from "../../services/validateModal.service";

interface ValidateModalProps {
}

const ValidateModal: FC<ValidateModalProps> = () => {
        const [messages, setMessage] = React.useState<any[]>([]);

        useEffect(() => {

            validateModalService.getMessage().subscribe((m) => {
                setMessage((oldMessages) => [...oldMessages, m]);
            });
        }, []);

        function removeMessage() {
            setMessage((prev) =>
                prev.filter((m, index) =>
                    index !== prev.length - 1
                ));
        }

        if (messages?.length > 0) {
            return (
                <div className={styles.ValidateModal}>
                    <div id={"hider-message"} className="menu-hider menu-active"></div>
                    <div id="menu-cookie-modal"
                         className="menu menu-box-modal menu-box-round-medium menu-box-detached rounded-s menu-active"
                         data-menu-height="250" data-menu-width="310" data-menu-effect="menu-over"
                         data-menu-select="page-components"
                         style={{display: "block", width: "310px", height: "380px"}}>
                        <div className="px-3 text-center">
                            <h1 className="text-center mt-4 mb-0">{messages[messages.length - 1].from_client_name} a
                                confirmé</h1>
                            <p className="text-center mt-n2 mb-3 font-12 color-green-dark">{messages[messages.length - 1].answer}</p>
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{position: "inherit", transform:"inherit", height:"180px", width:"180px"}}>
                                    <path xmlns="http://www.w3.org/2000/svg" fill={"#8cc152"}
                                          d="M440.1 103C450.3 112.4 450.3 127.6 440.1 136.1L176.1 400.1C167.6 410.3 152.4 410.3 143 400.1L7.029 264.1C-2.343 255.6-2.343 240.4 7.029 231C16.4 221.7 31.6 221.7 40.97 231L160 350.1L407 103C416.4 93.66 431.6 93.66 440.1 103V103z"/>
                                </svg>
                            </p>

                            <button
                                onClick={e => removeMessage()}
                                className="close-menu mb-4 btn btn-m btn-center-xl rounded-s shadow-m bg-green-dark   text-uppercase font-900">Ok
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div></div>;
        }
    }
;

export default ValidateModal;
