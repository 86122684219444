import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface MenuProps {}

const Menu: FC<MenuProps> = () => {
  const [code, setCode] = useState<string | null>(localStorage.getItem("code"));

  useEffect(() => {
    setCode(localStorage.getItem("code"));
  }, []);

  if (code === null) {
    return (
      <div id="footer-bar" className="footer-bar-1">
        <Link to="/home">
          <i className="fa fa-home"></i>
          <span>Home</span>
        </Link>
        <Link to="/">
          <i className="fa fa-cog"></i>
          <span>Settings</span>
        </Link>
      </div>
    );
  } else {
    return (
      <div id="footer-bar" className="footer-bar-1">
        <Link to="/home">
          <i className="fa fa-home"></i>
          <span>Home</span>
        </Link>
      </div>
    );
  }
};

export default Menu;
