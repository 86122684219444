import { FC, useEffect, useState } from "react";
import apiService from "../../services/api.service";

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const [code, setCode] = useState<string>();
  const [name, setName] = useState<string>();
  useEffect(() => {
    const temp_code = localStorage.getItem("code");
    setCode(temp_code!);
  }, []);
  useEffect(() => {
    if (code !== undefined) {
      apiService.getClientDataByCode(code!).then((client) => {
        setName(client.data[0].name);
      });
    }
  }, [code, name]);

  return (
    <div className="header header-fixed header-logo-center">
      <h1 className="header-title">{name ? name : "D'Office"}</h1>
    </div>
  );
};

export default Header;
