import axios from "axios";
import Cookies from "universal-cookie";

const url = process.env.REACT_APP_API_URL;
const cookies = new Cookies();

const apiData = {
    getHeaders: function () {
        return { 'Authorization': 'Token ' + cookies.get('token') }
    },
    connect: function (email: string, password: string) {

        return axios.post(url + 'auth/token/login', { username: email, password: password })
    },
    getFlowData: function () {
        return axios.get(url + "flow/");
    },
    getFlowById: function (flow_id: any) {
        return axios.get(url + "flow/" + flow_id + "/")
    },
    getFlowsdataFromClient: function (client_id: number) {
        return axios.get(url + "flow/?from_client=" + client_id);
    },
    addInformationBlock: function (informationBlock: any) {
        return axios.post(url + "information_block/", informationBlock, { headers: this.getHeaders() });
    },
    updateInformationBlock: function (informationBlock: any) {
        return axios.put(url + "information_block/" + informationBlock.id + "/", informationBlock, { headers: this.getHeaders() });
    },
    deleteInformationBlock: function (informationBlock: any) {
        return axios.delete(url + "information_block/" + informationBlock.id + "/", { headers: this.getHeaders() });
    },
    getInformationBlocks: function () {
        return axios.get(url + "information_block/");
    },
    getInformationBlocksByClient: function (client_id: number) {
        return axios.get(url + "information_block/?clients=" + client_id);
    },
    addFlow: function (flow: any) {
        return axios.post(url + "flow/", flow, { headers: this.getHeaders() });
    },
    updateFlow: function (flow: any) {
        return axios.put(url + "flow/" + flow.id + "/", flow, { headers: this.getHeaders() });
    },
    deleteFlow: function (flow: any) {
        return axios.delete(url + "flow/" + flow.id + "/", { headers: this.getHeaders() })
    },
    getClientData: function () {

        return axios.get(url + "client/");
    },
    getClientDataByCode: function (code: string) {
        console.log(url)
        return axios.get(url + "client/?code=" + code)
    },
    updateClient: function (client: any) {
        return axios.put(url + "client/" + client.id + "/", client, { headers: this.getHeaders() });
    },
    getClientDataById: function (client_id: number) {
        return axios.get(url + "client/" + client_id + "/");
    },
    getActions: function () {
        return axios.get(url + "action/");
    },
    getAction: function (action_id: number) {
        return axios.get(url + "action/" + action_id + "/");
    },
    addAction: function (action: any) {
        return axios.post(url + "action/", action, { headers: this.getHeaders() });
    },
    updateAction: function (action: any) {
        return axios.put(url + "action/" + action.id + "/", action, { headers: this.getHeaders() });
    },
    deleteAction: function (action: any) {
        return axios.delete(url + "action/" + action.id + "/", { headers: this.getHeaders() });
    },
    createClient: function (client: any) {
        return axios.post(url + "client/", client, { headers: this.getHeaders() });
    },
    getAnswers: function () {
        return axios.get(url + "answerFlow/");
    },
    addAnswer: function (answer: any) {
        return axios.post(url + "answerFlow/", answer, { headers: this.getHeaders() });
    },
    updateAnswer: function (answer: any) {
        return axios.put(url + "answerFlow/" + answer.id + "/", answer, { headers: this.getHeaders() });
    },
    deleteAnswer: function (answer: any) {
        return axios.delete(url + "answerFlow/" + answer.id + "/", { headers: this.getHeaders() });
    }
};
export default apiData;
