import React, { useState } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { JsxElement } from "typescript";

const Home = React.lazy(() => import("./components/Home/Home.lazy"));
const Login = React.lazy(() => import("./components/Login/Login.lazy"));
const Admin = React.lazy(() => import("./components/Admin/Admin.lazy"));
const Settings = React.lazy(
  () => import("./components/Settings/Settings.lazy")
);
const Loading = () => <p>Loading ...</p>;

const Main = () => {
  return (
    <React.Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/" element={<Settings />} />
        </Route>
        <Route path="home" element={<Home />} />
        <Route path="admin" element={<Admin />} />
        <Route path="login" element={<Login />} />
      </Routes>
    </React.Suspense>
  );
};
export default Main;

export const ProtectedRoute = () => {
  const [code] = useState<string | null>(localStorage.getItem("code"));
  if (code !== null) {
    // user is not authenticated
    return <Navigate to="/home" />;
  } else {
    return <Outlet />;
  }
};
