import { useEffect } from "react";
import "./App.css";
import Header from "./components/Header/Header";
import Menu from "./components/Menu/Menu";
import Snackbar from "./components/Snackbar/Snackbar";
import UpdateModal from "./components/UpdateModal/UpdateModal";
import ValidateModal from "./components/ValidateModal/ValidateModal";
import Main from "./main";
import { useServiceWorker } from "./serviceWorkerRegistration";

function App() {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  // decides when to show the toast
  useEffect(() => {
    if (showReload) {
      <UpdateModal makeUpdateHandler={reloadPage()}></UpdateModal>;
    }
  }, [waitingWorker, showReload, reloadPage]);
  return (
    <div className="App">
      <Header></Header>
      <Snackbar></Snackbar>
      <ValidateModal></ValidateModal>
      <Menu></Menu>
      <Main></Main>
    </div>
  );
}

export default App;
