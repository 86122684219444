import React, {FC, useEffect} from "react";
import snacbarService from "../../services/snackbar.service";
import snackbarService from "../../services/snackbar.service";

interface SnackbarProps {
}

const Snackbar: FC<SnackbarProps> = () => {
    const [messages, setMessage] = React.useState<string[]>([]);
    const [errorMessages, setErrorMessages] = React.useState<string[]>([]);

    useEffect(() => {
        snacbarService.getMessage().subscribe((m) => {
            setMessage((oldMessages) => [...oldMessages, m]);
            removeMessage();
        });
        snackbarService.getErrorMessage().subscribe((m) => {
            setErrorMessages((oldMessages) => [...oldMessages, m]);
            removeErrorMessage();
        });
    }, []);

    function removeMessage() {
        setTimeout(() => {
            let returnedMessages = messages.slice(0, 1);
            setMessage(returnedMessages);
        }, 5000);
    }
    function removeErrorMessage() {
        setTimeout(() => {
            let returnedMessages = errorMessages.slice(0, 1);
            setErrorMessages(returnedMessages);
        }, 5000);
    }

    if (messages?.length > 0 || errorMessages.length > 0) {
        return (
            <div>
                {messages.map((message: string, index) => (
                    <div key={index} className="snackbar-toast bg-green-dark fade show">
                        <i className="fa fa-check me-3"></i> {message}
                    </div>
                ))}
                {errorMessages.map((message: string, index) => (
                    <div key={index} className="snackbar-toast bg-red-dark fade show">
                        <i className="fa fa-times me-3"></i> {message}
                    </div>
                ))}
            </div>
        );
    } else {
        return <div></div>;
    }
};

export default Snackbar;
